<template>
	<el-container>
		<!-- 头部 -->
		<div class="banner" v-if="is_show">
			<el-header class="flex">
				<div class="el-header-left ">
					<img src="../assets/images/rts_Logo.png" alt="" ondragstart="return false;">
					<!-- <div class="el-header-left-loginBox ">
                       <div class="el-header-left-loginBox-text">
                           系统登录
                       </div>
                       <div class="el-header-left-loginBox-username">
                           当前用户：<span>OPS</span>
                       </div>
                   </div> -->
				</div>
				<div class="el-header-con">
					{{(this.deviceType.type == 'jhf' || this.deviceType.type == 'jlt'  || this.deviceType.type == 'pvcl') ? this.deviceType.project + '智能优化控制系统': '和隆优化智能优化控制云服务平台（HeroRTS）'}}
				</div>

				<div class="el-header-right flex align-cen">

					<div class="el-header-right-time">
						<!-- <div> {{nowDate}}</div> -->
						<div><span>当前用户：</span>{{UserInfo[1]}}</div>
					</div>
					<div class="el-header-right-logout ma-auto" @mouseenter="enter('logout')"
						@mouseleave="leave('logout')" ondragstart="return false;">
						<img :src="outimg" alt="" @click="loginout(2)">
					</div>
					<div class="right-btn">
						<div>
							<div @click="loginout(1)">通讯总览</div>
						<div @click="loginout(3)">趋势组图</div>
						</div>
						<div style="margin-left:0.5vw;">
							<div @click="loginout(4)">业绩地图</div>
						<div @click="loginout(5)">CEI统计</div>
						</div>
				
					</div>
				<!-- 	<div class="el-header-right-user"  @mouseenter="enter('user')" @mouseleave="leave('user')" ondragstart="return false;">
                     <img :src="userimg" alt="">
                 </div> -->
				</div>
			</el-header>
		</div>
		<el-main>
			<transition name="slide">
				<router-view :infoList="infoList" />
			</transition>
			<!-- 右侧栏 -->
		</el-main>
		<!-- 底部 -->
		<el-footer v-if="$route.path !='/firstcontol' && $route.path !='/show'">
			<ns-footer />
		</el-footer>
	</el-container>
</template>
<script>
	import NsFooter from "./components/NsFooter"
	import index from "./_index/index.js";
	import {
		log_add
	} from "@/api/auth/index" 
	export default {
		name: "Layout",
		components: {
			NsFooter,
		},
		created() {

		},
		data: () => {
			return {
				deviceType: {},
				indename: 'Jrindex',
				adList: [],
				is_show: true,
				indexTopAdNum: 0,
				nowDate: "", // 当前日期
				conHeight: '',
				userimg: require('../assets/images/rts_login.png'),
				outimg: require('../assets/images/rts_Logout.png'),
				scaleHeight: '',
				timer: null,
				UserInfo: [],
				infoList: {},
			}
		},
		mixins: [index],
		mounted() {
			this.currentTime()
			// 获取屏幕可视高度 减去头部底部 赋值给中间内容部分
			// 获取内容右边div高度 赋值给内容右边盒子
			// setTimeout(() => {
			// //修改数据
			// this.changValue = 1;
			// //DOM还没更新
			// this.$nextTick(() => {
			//     //DOM现在更新了
			//     this. bodyScale()
			//     var calcHeight = document.body.offsetHeight - 140 + "px";
			//       let obj = sessionStorage.getItem('deviceInfo')
			//         let deviceInfo = JSON.parse(obj)
			//     if(deviceInfo.type == 'rfl'){
			//         this.conHeight = "height:" + calcHeight;
			//     }else{
			//     this.conHeight = [{'height':calcHeight},{width:'auto'}];
			//     }

			// });
			//  }
			// , 
			// 500);
		},
		computed: {},
		watch: {},
		created() {
			let httptime = process.env.NODE_ENV === 'development' ? 5000 : 5000
			this.deviceType = JSON.parse(localStorage.getItem("deviceType"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.getInfo()
			this.timer = setInterval(() => {
				setTimeout(this.getInfo(), 0);
			}, httptime);
			// 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
			this.$once("hook:beforeDestroy", () => {
				clearInterval(this.timer);
			});
		},
		methods: {
			// 通讯总览 退出登录
			loginout(type) {
				if (type == 1)
				
				 return this.$router.push({
					path: '/userotherPage'
				});
				if (type == 2) return localStorage.clear(), this.$router.push({
					path: '/'
				})
				if (type == 3) return this.$router.push({
					path: '/trendGroup'
				});
				if (type == 4) return this.$router.push({
					path: '/mapIndex'
				});
				if (type == 5) return this.$router.push({
					path: '/CeiPage'
				});
			},
			// 鼠标移入
			enter(index) {
				if (index == 'user') return this.userimg = require('../assets/images/rts_loginactive.png')
				if (index == 'logout') return this.outimg = require('../assets/images/rts_Logoutactive.png')
			},
			leave(index) {
				if (index == 'user') return this.userimg = require('../assets/images/rts_login.png')
				if (index == 'logout') return this.outimg = require('../assets/images/rts_Logout.png')
			},
			currentTime() {
				setInterval(this.formatDate1, 500);
			},
			formatDate1() {
				this.nowDate = this.$util.formatDate('yyyy/MM/dd mm:hh:ss week');
			}
		},
		// 销毁定时器
		beforeDestroy() {
			if (this.formatDate) {
				clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
			}
		}
	}
</script>
<style lang="scss" scoped>
	.el-container {
		height: 100vh;
		background-image: linear-gradient(#0E3449 60%,
				#0E3146 61%,
				#0C2A38 100%),
			linear-gradient(#0E3449,
				#0E3449);
		background-blend-mode: normal, normal;
		// overflow: hidden;
	}

	.banner {
		// text-align: center;
		height: 9vh;
		// position: relative;
		background-image: linear-gradient(-45deg,
				#0b385f 3%,
				#005071 19%,
				#10526b 51%,
				#205365 100%),
			linear-gradient(#205161,
				#205161);
		background-blend-mode: normal, normal;
	}

	.el-header {
		padding: 0;
		height: 8vh;

		.el-header-left {
			height: 8vh;

			img {
				// width: 15vw;
				height: 8vh;
			}
		}

		.el-header-left-loginBox {
			height: inherit;
			width: 250px;
			background: $base-color-success;
			height: inherit;
			text-align: center;

			div {
				height: 45px;
				line-height: 45px;
			}

			.el-header-left-loginBox-text {
				color: $base-color-warning;
				font-size: 25px;

				border-bottom: 1px solid #fff;
			}

			.el-header-left-loginBox-username {
				font-size: 25px;
				color: #fff;

				span {
					color: $base-color-warning;
				}
			}
		}

		.el-header-con {
			width: 61vw;
			line-height: 8vh;
			text-align: center;
			font-size: 2vw;
			color: #FDF061;
			// text-shadow: 5px 5px 5px #000;
		}

		.el-header-right {

			height: 8vh;
			width: 24vw;
			
			// background: $base-color-success;

			.el-header-right-time {
				div {
					
					// background-color: white;
					color: #fff;
					font-size: 1vw;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vw;
					letter-spacing: 0vw;

					// padding-right: 30px;
					span {
						color: #5AB5CE;
					}
				}
			}

			.right-btn {
				margin-left: auto;
				// margin-top: 2vh;
				display: flex;


				div {
					color: #fff;
					background-color: #205365;
					width: 6vw;
					text-align: center;
					height: 3vh;
					margin-bottom: 0.5vh;
					line-height: 3vh;
					
					cursor: pointer;
				}
			}

			.el-header-right-user,
			.el-header-right-logout {
				cursor: pointer;

				img {
					width: 60px;
					height: 60px;
				}
			}

		}

	}

	.el-footer {
		padding: 0;
		height: 6vh;
		position: absolute;
		bottom: 0;
		width: 100vw;
		z-index: 3;
	}
</style>
